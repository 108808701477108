<template>
  <TosListVuetify entityName="parametri" :headers="headers" :prepareItemFunction="prepareItems">
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="3">
            <v-select clearable :label="tLabel('soggetto')" v-model.number="slotProps.data.soggettoId" item-text="label" item-value="id" :items="soggettiList" />
          </v-col>
          <v-col cols="3">
            <v-select clearable :label="tLabel('tipoParametro')" v-model="slotProps.data.tipo" :items="tipiParametroList"/>
          </v-col>
          <v-col cols="3">
            <v-select clearable :label="tLabel('nomeParametro')" v-model="slotProps.data.nome___LIKE" :items="nomeParametroList"/>
          </v-col>
          <v-col cols="3">
            <v-text-field clearable :label="tLabel('parametro')" v-model="slotProps.data.valore___LIKE"> </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
          <v-col cols="3">
            <v-select :label="tLabel('soggetto')" v-model.number="slotProps.data.soggettoId" item-text="label" item-value="id" :items="soggettiList" :error-messages="slotProps.errors.soggettoId" />
          </v-col>
          <v-col cols="3">
            <v-select :label="tLabelRequired('tipoParametro')" v-model="slotProps.data.tipo" :items="tipiParametroList" 
            :rules="[rules.required]" :error-messages="slotProps.errors.tipo" />
          </v-col>
          <v-col cols="3">
            <v-select :label="tLabelRequired('nomeParametro')" v-model="slotProps.data.nome" :items="nomeParametroList" 
            :rules="[rules.required]" :error-messages="slotProps.errors.nome" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea :label="tLabel('parametro') + '*'" v-model="slotProps.data.valore" :error-messages="slotProps.errors.valore" />
          </v-col>
        </v-row>
      </div>
    </template>
  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "DestinatariReport",
  data() {
    return {
      soggettiList: null,
      headers: [
        { text: "", value: "action" },
        { text: "Cliente.", value: "soggetto" },
        { text: "Tipo", value: "tipo" },
        { text: "Nome", value: "nome" },
        { text: "Valore", value: "valore" },
      ],
      tipiParametroList:[
        "CARICO", 
        "VUOTI GDF",
        "PREAVVISO MTO",
        "PREAVVISO COPINO"
      ],
      nomeParametroList:["Destinatari GDF", "Destinatari MTO", "Destinatari IF", "Destinatari Preavviso", "Oggetto Mail", "Testo Mail"],
    };
  },
  async mounted() {
    this.soggettiList = (await this.$api.get(this.$apiConfiguration.BASE_PATH + "soggetti/shortList")).data;      
  },
  methods: {
    validate(item) {
      let errors = {};
      return errors;
    },
    prepareItems(item) {
      if (item.listCategorie == null) item.listCategorie = [];
    },
  },
  components: {
    TosListVuetify: TosListVuetify,
  },
  mixins: [FormatsMixins],
};
</script>
